import React from 'react'
import ClientCard from 'components/client-card'
import PropTypes from 'prop-types'
import { window } from 'browser-monads'
import { woodwatch_1, woodwatch_2, woodwatch_3, bibbr_2, avy_2, yummygum_2, bibbr_logo } from 'images/klanten'
import Layout from '../../layouts'
import Helmet from 'react-helmet'

const dummyKlantVerhaal = {
  pageTitle: '"Ik wilde meer mensen aannemen, maar niet via payroll, dat kost teveel."',
  pageStreamer: 'WoodWatch te Rotterdam, Kevin van der Veer',
  useCase: '15 werknemers',
  person: {
    name: 'Kevin van der Veer',
    functionTitle: 'Eigenaar WoodWatch',
    description: 'De horloges zijn stuk voor stuk uniek en je ziet het vakmanschap erachter tot in de details',
    portraitImage: woodwatch_1,
    portraitImageSmall: woodwatch_1,
    internallink: false,
    link: 'https://www.woodwatch.com/nl/',
    btnText: 'Bekijk website&nbsp;→'
  },
  content: [{
    title: 'Bedrijf',
    description: 'Sinds 2013 zijn Daniël Salimian, Kevin van der Veer en Jeroen Westerbeek actief met hun bedrijf WoodWatch. Het bedrijf levert houten horloges via hun eigen webshop. Kevin: “We zijn op het idee gekomen, omdat we houten producten wel cool vinden. Zo zag je al wel houten zonnebrillen, maar nog geen horloges. Daar zijn we toen op in gesprongen”. En dat blijkt aan te spreken; WoodWatch stond in 2017 op nummer 3 van de Emerce top 50 van snelst groeiende webshops. Met een omzet van ruim een half miljoen een hele prestatie!<br/><br/>We hebben afgesproken met Kevin van der Veer op het kantoor van WoodWatch in Rotterdam. We praten met hem over zijn ervaringen met personeel aannemen.'
    }, {
      title: 'Personeel',
      description: 'Kevin geeft aan: “Wij zaten helemaal in het begin bij een payrollbedrijf, omdat we gewoon niet wisten hoe we het anders moesten doen. We hadden ons aangemeld als werkgever en kregen direct brieven van pensioenfondsen. Omdat we daar toen niet zo goed raad mee wisten en geen tijd hadden om ons hier zwaar in te verdiepen zijn we naar een payrollbedrijf gestapt. Op de vraag wat betaalde je dan, geeft Kevin aan: “We betaalden een factor 1,7 tot 1,8 op het brutoloon. Toen we vervolgens verder uit gingen breiden met ons personeelsbestand, werd dit gewoon te duur en zijn we naar alternatieven gaan zoeken.”'
    }, {
      image: woodwatch_3
    },{
      title: 'Waarom gekozen voor Employes?',
      description: 'We vragen aan Kevin waarom hij heeft gekozen voor Employes. Kevin: “Naast de prijs is ook gemak een belangrijk item geweest. Ondanks dat je met Employes toch min of meer je salarisadministratie zelf doet, is het niet moeilijk. Dit heeft ervoor gezorgt dat we gemakkelijk mensen aan kunnen nemen zonder dat we ons zorgen hoeven te maken over de administratie. Dat is heel prettig! We zijn een jong bedrijf dat hard groeit en we willen graag doen waar we goed in zijn, en dat is niet loonadministratie. Maar het moet wel goed geregeld zijn.”'
    }, {
      title: 'Wat kan er beter',
      description: 'Kevin geeft ons ook nog een goede tip: “Ik had ooit een situatie met een werknemer waarvoor ik een verzekering moest afsluiten. Daar moest ik van alles voor aanleveren. Hoe mooi zou het zijn als je met Employes met 1 klik je werknemers kunt verzekeren.”'
    }, {
      quote: '“Gemak, het heeft voor ons de drempel weggehaald om ook daadwerkelijk mensen zelf aan te nemen”'
    }, {
      title: 'Tot slot',
      description: 'Tijdens ons bezoek aan WoodWatch hebben we een aantal horloges in het echt gezien. Die zien er fantastisch uit, net zoals de <a href="https://www.instagram.com/WoodWatch/" target="_blank">Instagram van WoodWatch</a>. We raden dan ook zeker aan om een kijkje te nemen.'
  }],
  otherClientStories:  [{
    name: 'Ruben Paijens',
    functionTitle: 'Mede-oprichter, Bibbr',
    companyLogo: '',
    description: 'Ik vind het vooral fijn dat jullie betrokken zijn.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: bibbr_2,
    quote: 'Ik vind het vooral fijn dat jullie betrokken zijn en makkelijk te bereiken zijn.',
    link: '/klanten/bibbr/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Vince Schwidder',
    functionTitle: 'Mede-oprichter, Yummygum',
    companyLogo: '',
    description: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: yummygum_2,
    quote: 'Sneller salarisadministratie verwerkt zonder gedoe.',
    link: '/klanten/yummygum/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }, {
    name: 'Ilse van Nierop',
    functionTitle: 'HR-manager, Avy',
    companyLogo: '',
    description: 'Learn modern web development and Hire the best trained developers. Cras curabitur amet pulvinar.',
    portraitImage: 'https://via.placeholder.com/348x206',
    portraitImageSmall: avy_2,
    quote: 'Ik kan onze salarisadministratie nu doen binnen 10 minuten',
    link: '/klanten/avy/',
    internallink: true,
    btnText: 'Lees ervaring&nbsp;→'
  }]
}


const klantverhaalTemplate = ({location}) => (
  <Layout location={location}>
    <main className="clientstory animated fadeInPage">

      <Helmet>
        <title>Interview met Kevin van der Veer WoodWatch| Employes</title>
        <meta name="description" content="Kevin aan het woord over hun salarisadministratie: Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel." />
        <meta itemprop="name" content="Interview met Kevin van der Veer WoodWatch| Employes" />
        <meta itemprop="description" content="Kevin aan het woord over hun salarisadministratie: Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel."/>
      </Helmet>

      <Helmet>
        <title>Interview met Kevin van der Veer WoodWatch | Employes</title>
        <meta name="description" content="Kevin aan het woord over hun salarisadministratie: Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel." />
        <meta itemprop="name" content="Interview met Kevin van der Veer WoodWatch| Employes" />
        <meta itemprop="description" content="Kevin aan het woord over hun salarisadministratie: Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel.."/>
        <meta itemprop="image" content="https://employes.nl/static/meta-woodwatch.jpg"/>

        {/* OpenGraph tags */}
        <meta property="og:url" content="https://employes.nl/klanten/woodwatch/"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="Interview met Kevin van der Veer WoodWatch| Employes"/>
        <meta property="og:description" content="Kevin aan het woord over hun salarisadministratie: Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel."/>
        <meta property="og:image" content="https://employes.nl/static/meta-woodwatch.jpg"/>

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="Interview met Kevin van der Veer WoodWatch| Employes"/>
        <meta name="twitter:description" content="Kevin aan het woord over hun salarisadministratie: Ik wilde meer mensen aannemen, maar niet via payroll, dat kost te veel."/>
        <meta name="twitter:image" content="https://employes.nl/static/meta-woodwatch.jpg"/>

      </Helmet>

      <header className="header padding-xl">
        <div className="container-md">
          <div className="grid between align-middle">
            <div className="col-6 no-pad">
              <h2>{ dummyKlantVerhaal.pageTitle }</h2>
              <p className="streamer large margin-l-bottom">{ dummyKlantVerhaal.pageStreamer }</p>
              <span className="badge green">Bedrijfsgrootte: <b>{ dummyKlantVerhaal.useCase }</b></span>
            </div>
            <ClientCard className="col-6 no-pad no-logo" person={dummyKlantVerhaal.person} isSmall={(window.innerWidth < 768)}/>
          </div>
        </div>
      </header>
      <section className="up">
        <div className="clientstory-content padding-xl">
            {dummyKlantVerhaal.content.map((section, idx) => {
              const firstObjectKey = Object.keys(section)[0]
              const isImage = (firstObjectKey === 'image')
              const { title, description } = section

              return (
                <div className="paragraph margin-m-bottom" key={idx}>
                  <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                    <div className="grid yg center">
                      <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                      {{
                        title: (
                          <div className="text">
                            <h4>{ title }</h4>
                            <p className="large" dangerouslySetInnerHTML={ { __html: description } }></p>
                          </div>
                        ),
                        quote: (
                          <div className="client-quote">
                            “Het heeft voor ons de drempel weggehaald om mensen zelf aan te nemen.”
                          </div>
                        ),
                        image: (
                          <img src={section.image} alt="klant van Employes"/>
                        )
                      }[firstObjectKey]}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div className="clientstory-others padding-xl">
          <div className="container-md">
            <div className="grid yg center text-center margin-l-bottom">
              <div className="col-12">
                <h6 className="eyebrow">Ervaringen</h6>
                <h2 className="no-margin">Andere klantverhalen</h2>
              </div>
            </div>
            <div className="grid yg">
              {dummyKlantVerhaal.otherClientStories.map((otherclient, idx) => {
                const { name, functionTitle, companyLogo, portraitImage, quote } = otherclient

                return (
                  <div className="col-4" key={otherclient.name} key={idx}>
                    <ClientCard person={otherclient} isSmall={true}/>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </section>
    </main>
  </Layout>
)

export default klantverhaalTemplate
